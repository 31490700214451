<template>
  <div class="live-mob">
    <mob-header></mob-header>
    <div class="main">
      <yl-swiper :dataList="slideDataList"></yl-swiper>
      <mob-tab @change="change"></mob-tab>
      <div class="wrap" v-if="index == 0">
        <mob-video
          v-for="(item, i) in nowLiveList"
          :key="i"
          :data="item"
          @refDataList="change(0)"
        ></mob-video>
      </div>
      <div class="wrap" v-else-if="index == 1">
        <mob-video
          v-for="(item, i) in waitLiveList"
          :key="i"
          :data="item"
          @refDataList="change(1)"
        ></mob-video>
      </div>
      <div class="wrap" v-else-if="index == 2">
        <mob-video
          v-for="(item, i) in reviewLiveList"
          :key="i"
          :data="item"
          @refDataList="change(2)"
        ></mob-video>
      </div>
    </div>
    <mob-footer-bar :active="2"></mob-footer-bar>
  </div>
</template>

<script>
import mobFooterBar from "../../components/mob-footer-bar.vue";
import MobHeader from "../../components/mob-header.vue";
import YlSwiper from "../../components/yl-swiper.vue";
import MobTab from "../../components/mob-tab.vue";
import MobVideo from "../../components/mob-video.vue";
import { slideList } from "../../api/home";
import { liveList } from "../../api/live";
import wechat from "../../utils/wxShare";
import { wechatConfig } from "../../api/user";
export default {
  components: {
    mobFooterBar,
    MobHeader,
    YlSwiper,
    MobTab,
    MobVideo,
  },
  data() {
    return {
      index: 1,
      slideDataList: [],
      waitLiveList: [],
      nowLiveList: [],
      reviewLiveList: [],
    };
  },
  created() {
    this.getSlideList();
    this.getLiveList(1);
    this.getWechatConfig();
  },
  methods: {
    getWechatConfig() {
      wechatConfig({
        url: location.href,
      }).then((res) => {
        if (res.code == 0) {
          this.wxConfigData = res.data;
          wechat.share(
            this.wxConfigData,
            "因美纳在线讲堂",
            "因美纳在线讲堂",
            this.$store.state.url,
            location.href
          );
        }
      });
    },
    change(i) {
      this.index = i;
      if (i == 0) {
        this.getLiveList(0);
      } else if (i == 1) {
        this.getLiveList(1);
      } else if (i == 2) {
        this.getLiveList(2);
      }
    },
    // 轮播图数据
    getSlideList() {
      slideList().then((res) => {
        this.slideDataList = res.data;
      });
    },
    // 查询直播数据
    getLiveList(number) {
      let formData = {};
      if (number == 0) {
        formData = {
          liveStatus: "1",
          type: "0",
          rank: "liveTime",
          rankTwo: "creationTime",
        };
      } else if (number == 1) {
        formData = {
          liveStatus: "0",
          type: "0",
          rank: "liveTime",
          rankTwo: "creationTime",
        };
      } else if (number == 2) {
        formData = {
          liveStatus: "2",
          key: "1,0",
          rank: "liveTime",
          rankTwo: "creationTime",
        };
      }
      liveList(formData).then((res) => {
        if (number == 0) {
          this.nowLiveList = res.data;
        } else if (number == 1) {
          this.waitLiveList = res.data;
        } else if (number == 2) {
          this.reviewLiveList = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.live-mob {
  height: 100%;
  background: url("../../assets/mobbgsmall.png") 0 0 no-repeat;
  background-size: 100% auto;
  .main {
    padding: 0 0.1rem;
    .yl-swiper {
      border: none;
      height: 1.68rem;
      border-radius: 0.08rem;
    }
    .mob-tab {
      margin: 0.1rem 0;
    }
    .wrap {
      padding-bottom: 0.9rem;
    }
  }
}
</style>
