<template>
  <div class="page-view">
    <live-mob v-if="isMobile"></live-mob>
    <live-pc v-else></live-pc>
  </div>
</template>

<script>
import LivePc from "./live-pc.vue";
import LiveMob from "./live-mob.vue";
export default {
  components: {
    LivePc,
    LiveMob,
  },
  metaInfo: {
    title: "因美纳讲堂 | 直播",
  },
  data() {
    return {
      isMobile: this.$isMobile(),
    };
  },
  created() {
    // console.log(this.isMobile);
  },
};
</script>
