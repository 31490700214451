<template>
  <div class="live-pc">
    <pc-header></pc-header>
    <div class="main">
      <yl-swiper :dataList="slideDataList"></yl-swiper>
      <pc-tab @change="change"></pc-tab>
      <div class="box" v-if="index == 0">
        <pc-video
          v-for="(item, i) in nowLiveList"
          :key="i"
          :data="item"
          @refDataList="change(0)"
        ></pc-video>
      </div>
      <div class="box" v-else-if="index == 1">
        <pc-video
          v-for="(item, i) in waitLiveList"
          :key="i"
          :data="item"
          @refDataList="change(1)"
        ></pc-video>
      </div>
      <div class="box" v-else-if="index == 2">
        <pc-video
          v-for="(item, i) in reviewLiveList"
          :key="i"
          :data="item"
          @refDataList="change(2)"
        ></pc-video>
      </div>
    </div>
    <pc-footer></pc-footer>
  </div>
</template>

<script>
import PcHeader from "../../components/pc-header.vue";
import PcFooter from "../../components/pc-footer.vue";
import YlSwiper from "../../components/yl-swiper.vue";
import PcTab from "../../components/pc-tab.vue";
import PcVideo from "../../components/pc-video.vue";
import { slideList } from "../../api/home";
import { liveList } from "../../api/live";
export default {
  components: {
    PcHeader,
    PcFooter,
    YlSwiper,
    PcTab,
    PcVideo,
  },
  data() {
    return {
      slideDataList: [],
      index: 1,
      waitLiveList: [],
      nowLiveList: [],
      reviewLiveList: [],
    };
  },
  created() {
    this.getSlideList();
    this.getLiveList(1);
  },
  methods: {
    // 轮播图数据
    getSlideList() {
      slideList().then((res) => {
        this.slideDataList = res.data;
      });
    },
    change(i) {
      this.index = i;
      if (i == 0) {
        // this.getLiveList(0, 0, 1);
        this.getLiveList(0);
      } else if (i == 1) {
        // this.getLiveList(1, 0, 0);
        this.getLiveList(1);
      } else if (i == 2) {
        this.getLiveList(2);
        // this.reviewLiveList = [];
        // this.getLiveList(2, 1);
        // this.getLiveList(2, 0, 2);
      }
    },
    // 查询直播数据
    getLiveList(number) {
      let formData = {};
      if (number == 0) {
        formData = {
          liveStatus: "1",
          type: "0",
          rank: "liveTime",
          rankTwo: "creationTime",
        };
      } else if (number == 1) {
        formData = {
          liveStatus: "0",
          type: "0",
          rank: "liveTime",
          rankTwo: "creationTime",
        };
      } else if (number == 2) {
        formData = {
          liveStatus: "2",
          key: "1,0",
          rank: "liveTime",
          rankTwo: "creationTime",
        };
      }
      liveList(formData).then((res) => {
        if (number == 0) {
          this.nowLiveList = res.data;
        } else if (number == 1) {
          this.waitLiveList = res.data;
        } else if (number == 2) {
          this.reviewLiveList = res.data;
        }
      });
      // liveList({
      //   type: type,
      //   liveStatus: status,
      // }).then((res) => {
      //   if (res.code == 0) {
      //     if (number == 0) {
      //       this.nowLiveList = res.data;
      //     } else if (number == 1) {
      //       this.waitLiveList = res.data;
      //     } else if (number == 2) {
      //       let temp = this.reviewLiveList;
      //       this.reviewLiveList = temp.concat(res.data);
      //       this.reviewLiveList.sort(function (obj1, obj2) {
      //         if (obj1.sort !== obj2.sort) {
      //           return Number(obj1.sort) > Number(obj2.sort) ? -1 : 1;
      //         } else if ((obj1.liveTime ? obj1.liveTime : obj1.creationTime) !== (obj2.liveTime ? obj2.liveTime : obj2.creationTime)) {
      //           let a = new Date(obj1.liveTime ? obj1.liveTime : obj1.creationTime);
      //           let b = new Date(obj2.liveTime ? obj2.liveTime : obj2.creationTime);
      //           return a.getTime() > b.getTime() ? -1 : 1;
      //         } else {
      //           return obj1.id - obj2.id;
      //         }
      //       });
      //       // this.reviewLiveList = res.data;
      //     }
      //   }
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.live-pc {
  height: 100%;
  background: url("../../assets/pcbg.png") 0 0 no-repeat;
  background-size: 100% auto;
  .main {
    width: 1200px;
    margin: 0 auto;
    .yl-swiper {
      height: 406px;
      border-radius: 8px;
      margin-top: 36px;
    }
    .pc-tab {
      margin: 50px 0 30px;
    }
    .box {
      padding-bottom: 100px;
      display: flex;
      flex-wrap: wrap;
      min-height: 200px;
      .pc-video {
        margin-bottom: 34px;
      }
      .pc-video:nth-child(3n-1) {
        margin: 0 15px 34px;
      }
    }
  }
}
</style>
