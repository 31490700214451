<template>
  <div class="mob-tab">
    <div class="item">
      <span :class="index == 1 ? 'active' : ''" @click="select(1)"
        >即将开始</span
      >
    </div>

    <i></i>
    <div class="item">
      <span :class="index == 0 ? 'active' : ''" @click="select(0)"
        >正在直播</span
      >
    </div>

    <i></i>
    <div class="item">
      <span :class="index == 2 ? 'active' : ''" @click="select(2)"
        >直播回顾</span
      >
    </div>
  </div>
</template>

<script>
/**
 * @property {Function} change 返回点击的tab的index
 */
export default {
  data() {
    return {
      index: 1,
    };
  },
  methods: {
    select(index) {
      this.index = index;
      this.$emit("change", index);
    },
  },
};
</script>

<style lang="scss" scoped>
.mob-tab {
  width: 100%;
  height: 0.5rem;
  border-radius: 0.08rem;
  background: #fff;
  display: flex;
  align-items: center;
  .item {
    display: inline-block;
    width: 33%;
    height: 0.5rem;
    line-height: 0.5rem;
    text-align: center;
    font-size: 0.14rem;
    color: #999;
    span {
      box-sizing: border-box;
      display: inline-block;
      height: 0.5rem;
      border-bottom: 2px solid rgba($color: #000000, $alpha: 0);
    }
    span.active {
      font-weight: bold;
      border-bottom: 2px solid #49b67b;
      color: #49b67b;
    }
  }
  i {
    display: inline-block;
    height: 0.15rem;
    border-right: 1px solid #ececec;
  }
}
</style>
