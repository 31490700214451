<template>
  <swiper class="yl-swiper" v-if="dataList.length > 0" :options="swiperOption">
    <swiper-slide :v="i" :class="[stack ? 'stack' : '', isMobile ? '' : 'pc']" v-for="(item, i) in dataList" :key="i" :style="{ width: stack ? (isMobile ? '2.15rem' : '495px') : '100%' }">
      <div :v="i">
        <div :v="i" class="img-box">
          <img v-if="!stack" :src="isMobile ? item.imgUrl : item.imgUrlPc" />
          <img v-if="stack" :src="item.kvUrl" />
          <img v-if="stack" class="play-icon" src="../assets/play.png" alt="" />
        </div>
        <div :v="i" v-if="stack" class="title">{{ item.subject }}</div>
      </div>
    </swiper-slide>

    <div v-if="pagination" class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import { getToken } from '../utils/auth';
import { userInfo } from '../api/user';
export default {
  name: 'Home',
  title: 'Pagination',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    stack: {
      type: Boolean,
      default: false
    },
    dataList: {
      type: Array,
      default: function () {
        return [];
      }
    },
    pagination: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      swiperOption: {
        // spaceBetween: 10,
        pagination: {
          el: this.pagination ? '.swiper-pagination' : '',
          clickable: true
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
          //   stopOnLastSlide: true,
        },
        loop: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        observer: true,
        observeSlideChildren: true,
        on: {
          click: e => {
            // console.log(e.srcElement.attributes.key.value);
            // console.log(e);
            if (!e.srcElement.attributes.v) return;
            var i = e.srcElement.attributes.v.value;
            this.toLink(this.dataList[i].junpLink, this.dataList[i].id, this.dataList[i].validatelogon);
          }
        }
      },
      isMobile: this.$isMobile()
    };
  },
  methods: {
    getUserInfo(junplink) {
      console.log(getToken());
      if (getToken()) {
        // 有token缓存，获取用户数据
        userInfo().then(res => {
          if (res.code == 0) {
            this.userInfo = res.data;
            this.collectCount = res.collectCount;
            this.subscribeCount = res.subscribeCount;
            this.logining = true;
            sessionStorage.setItem('userInfo', JSON.stringify(res.data));
            // 成功获取用户数据，跳转链接
            location.href = junplink;
          } else {
            // 没有查询到用户数据，跳转登录页面
            // this.logining = false;
            this.$router.push({
              path: '/login',
              query: { redirect: junplink }
            });
          }
        });
      } else {
        // 没有token缓存，跳转登录页面
        this.$router.push({ path: '/login', query: { redirect: junplink } });
      }
    },
    toLink(junplink, id, validatelogon) {
      if (this.stack) {
        this.$router.push({ path: '/watch/' + id });
      } else if (junplink) {
        // 轮播图设置了跳转链接
        if (validatelogon) {
          // 轮播图开启了验证登录后跳转链接
          this.getUserInfo(junplink);
        } else {
          // 未开启登录验证，直接跳转链接
          location.href = junplink;
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
img {
  width: 100%;
  display: block;
}
.yl-swiper {
  width: 100%;
  border: 1px transparent solid;
  --swiper-pagination-color: #0275c8;
  overflow: hidden;
  .stack {
    transition: all 300ms;
    .img-box {
      position: relative;
      width: 1.96rem;
      height: 1.07rem;
      border-radius: 0.06rem 0.06rem 0 0;
      overflow: hidden;
      margin: 0 auto;
      transition: all 300ms;
      .play-icon {
        width: 0.3rem;
        height: 0.3rem;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
      }
    }
    .title {
      height: 0.32rem;
      width: 1.96rem;
      line-height: 0.32rem;
      text-align: center;
      background: #fff;
      border-radius: 0 0 0.06rem 0.06rem;
      margin: 0 auto;
      font-weight: bold;
      font-size: 0.12rem;
      padding: 0 0.1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      box-sizing: border-box;
    }
    &.swiper-slide-active {
      .img-box {
        width: 2.15rem;
        height: 1.17rem;
      }
      .title {
        width: 2.15rem;
      }
    }
  }
  .stack.pc {
    .img-box {
      width: 448px;
      height: 240px;
      border-radius: 8px 8px 0 0;
      .play-icon {
        width: 55px;
        height: 55px;
      }
    }
    .title {
      height: 60px;
      width: 448px;
      line-height: 60px;
      font-size: 22px;
      border-radius: 0 0 8px 8px;
      padding: 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      box-sizing: border-box;
    }
    &.swiper-slide-active {
      .img-box {
        width: 495px;
        height: 265px;
      }
      .title {
        width: 495px;
        height: 70;
      }
    }
  }
}
</style>
